@tailwind base;
@tailwind components;
@tailwind utilities;
/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}

/*
  3. Allow percentage-based heights in the application
*/
html,
body,
#__next {
  height: 100%;
}

/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-family: "Futura-PT", "Roboto", "Helvetica", "Arial", sans-serif;

  /* ease-out */
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  /* ease-in */
  transition-timing-function: cubic-bezier(0.75, 0, 1, 1);
  /* ease-in-out */
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  /* ease */
  transition-timing-function: cubic-bezier(0.44, 0.21, 0, 1);
}

/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}

/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/*
  9. Create a root stacking context
*/

@media (prefers-reduced-motion: reduce) {
  * {
    animation-play-state: paused !important;
    transition: none !important;
    scroll-behavior: auto !important;
  }
}
#root,
#__next {
  isolation: isolate;
}

/*
  10. anchor underline color
*/
a {
  text-decoration-color: hsla(350, 85%, 42%, 0.4);
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: "ncqaIcons";
  src: url("assets/fonts/ncqaIcons.eot");
  src: url("assets/fonts//ncqaIcons.eot") format("embedded-opentype"), url("assets/fonts/ncqaIcons.ttf") format("truetype"),
    url("assets/fonts/ncqaIcons.woff") format("woff"), url("assets/fonts/ncqaIcons.svg") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

[class^="ico-"],
[class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  display: inline-block;
  vertical-align: middle;
  font-family: "ncqaIcons" !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-email-solid:before {
  content: "\e924";
}
.ico-explore:before {
  content: "\e925";
}
.ico-phone:before {
  content: "\e926";
}
.ico-pin:before {
  content: "\e927";
}

.ico-distinction .path1:before {
  content: "\e91f";
  color: currentColor;
}
.ico-distinction .path2:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ico-distinction .path3:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ico-distinction .path4:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ico-distinction .path5:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ico-check-circle:before {
  content: "\e91e";
}

.ico-external:before {
  content: "\e91d";
}

.ico-eco .path1:before {
  content: "\e919";
  color: currentColor;
}
.ico-eco .path2:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ico-eco .path3:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ico-info:before {
  content: "\e91c";
}
.ico-heart:before {
  content: "\e900";
}
.ico-medical-case:before {
  content: "\e901";
}
.ico-medical-record .path1:before {
  content: "\e902";
  opacity: 0;
}
.ico-medical-record .path2:before {
  content: "\e903";
  margin-left: -0.7080078125em;
  opacity: 0.2;
}
.ico-medical-record .path3:before {
  content: "\e904";
  margin-left: -0.7080078125em;
  color: rgb(255, 255, 255);
}
.ico-medical-record .path4:before {
  content: "\e905";
  margin-left: -0.7080078125em;
}
.ico-medical-record .path5:before {
  content: "\e906";
  margin-left: -0.7080078125em;
}
.ico-medical-record .path6:before {
  content: "\e907";
  margin-left: -0.7080078125em;
}
.ico-medical-record .path7:before {
  content: "\e90a";
  margin-left: -0.7080078125em;
}
.ico-play:before {
  content: "\e908";
}
.ico-sthethoscope:before {
  content: "\e909";
}

.ico-add:before {
  content: "\e90b";
}
.ico-arrow-down:before {
  content: "\e90c";
}
.ico-arrow-left:before {
  content: "\e90d";
}
.ico-arrow-right:before {
  content: "\e90e";
}
.ico-email:before {
  content: "\e90f";
}
.ico-facebook:before {
  content: "\e910";
}
.ico-filter-list:before {
  content: "\e911";
}
.ico-heart-outline:before {
  content: "\e912";
}
.ico-heart-solid:before {
  content: "\e913";
}
.ico-linkedin:before {
  content: "\e914";
}
.ico-print:before {
  content: "\e915";
}
.ico-search:before {
  content: "\e916";
}
.ico-twitter:before {
  content: "\e917";
}
.ico-unfold-more:before {
  content: "\e918";
}

.coming-wrapper {
  display: table-cell;
  vertical-align: middle;
  padding: 40px 0;
  color: #4d4d4d;
  /* background: #e8ebec; */
}

.coming-left-section {
  width: 50%;
  float: left;
}

.coming-left-section img {
  float: right;

  margin-right: 50px;
}

.coming-right-section {
  width: 50%;
  float: left;
}

#coming-details-coming-soon {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background: #e8ebec;
  padding: 5px 15px;
  font-family: "Futura-PT", sans-serif;
  font-weight: 500;
  font-size: 40px;
  text-transform: uppercase;
  letter-spacing: -1px;
  float: left;
  margin-bottom: 20px;
}

.coming-seprator {
  float: none;
  clear: both;
}

.coming-watch-section {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .coming-left-section {
    width: 30%;
    float: left;
  }

  .coming-left-section img {
    float: right;
    width: 70%;
    margin-right: 50px;
  }

  .coming-right-section {
    width: 70%;
  }
}
